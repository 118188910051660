<template>
    <modal ref="modalHistorialCambios" :titulo="titulo" tamano="modal-xl" :height="70" no-aceptar no-cancelar>
        <div class="row mx-0">
            <div class="col-6">
                <i class="icon-brightness" style="color:#FF1720;" />
                Cambio de precio de venta en los últimos 90 días
            </div>
            <div class="col-6">
                <i class="icon-brightness" style="color:#000000;" />
                Cambio de precio de compra en los últimos 90 días
            </div>
        </div>
        <div class="dwadawd" style="height:calc(70vh - 80px);">
            <echart ref="graph" height="100%" :options="historial" />
        </div>
    </modal>
</template>

<script>
import CedisPrecios from '~/services/cedis/cedis_productos_precios'
import Vue from 'vue'
import echarts from 'echarts'
Vue.component('echarts',echarts);
export default {
    data(){

        return {
            cant_decimales_cedis: 0,
            titulo: '',
            nombre: '',
            historial: {
                tooltip: {
                    trigger: 'axis',
                    position: function(point, params, dom, rect, size){
                        var obj = {top: 60};
                        obj[['left', 'right'][+(point[0] < size.viewSize[0] / 2)]] = 15;
                        return obj;
                    },
                    formatter: params => {
                        let msg =  [];
                        // console.log('params', params);

                        /* var string = '<div class="row mx-0"> ' */
                        var string = ''
                        params.forEach((element, idx) => {
                            const {imagen_firmada, justificacion, nombre, valor} = element.data.info
                            const cant_decimales_cedis = this.cant_decimales_cedis
                            const dia = element.data.dia
                            const color = element.color
                            const titulo = element.seriesIndex ===0? 'Cambio de precio de compra': 'Cambio de precio de venta'
                            string += `
                                        <div class="row mx-0 f-600"> ${titulo} </div>
                                        <i class="icon-brightness" style="color:${color};"></i> ${dia}
                                        <div class="row mx-0 mt-2">
                                            <div class="col-auto">
                                                <img src="${imagen_firmada}" width="42" height="42" class="rounded-circle obj-cover"/>
                                            </div>
                                            <div class="col text-muted2 py-2" style="background:#F5F5F5;border-radius:5px;">
                                                <div class="row">
                                                    <p class="col-auto text-muted2 f-600"> ${nombre} </p>
                                                    <p class="col-auto ml-auto text-general"> ${this.separadorNumero(valor, cant_decimales_cedis )}</p>
                                                </div>
                                                <p
                                                class=""
                                                style="color:#6D6D6D;width:280px;overflow: hidden;display: -webkit-box;-webkit-line-clamp: 6;-webkit-box-orient: vertical;white-space: pre-line;line-height: 10px;font-size:12px;"
                                                >
                                                    ${justificacion}
                                                </p>
                                            </div>
                                        </div>

                                    `

                        });
                        /* return string +='</div>'; */
                        return string;

                    }
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: {
                    show: false,
                    type: 'category',
                    data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
                },
                yAxis: {
                    type: 'value'
                },
                series: [
                    {
                        name: 'Step Start',
                        type: 'line',
                        step: 'start',
                        symbol: 'circle',
                        symbolSize: '8',
                        data: [],
                        itemStyle: {
                            color: '#000000'
                        }
                    },
                    {
                        name: 'Step Middle',
                        type: 'line',
                        step: 'middle',
                        symbol: 'circle',
                        symbolSize: '8',
                        data: [],
                        itemStyle: {
                            color: '#FF1720'
                        }
                    },
                ]
            }
        }
    },
    methods: {
        toggle(obj, info){
            //console.log(obj, info);
            this.nombre = info.nombre
            this.listarDatos(obj)
            this.cant_decimales_cedis = this.calcularMonedaCedis(obj.id_cedis, true)
            this.$refs.modalHistorialCambios.toggle();
            setTimeout(() => {
                this.$refs.graph.handleResize();
            }, 500);
        },
        async listarDatos(obj){
            try {
                const {data} = await CedisPrecios.graficaHistorialPrecios(obj)
                var eje_x = []
                for (let index = 1; index <= 90; index++){
                    eje_x.push(index);
                }
                this.historial.xAxis.data = eje_x
                this.historial.series[0].data = data.grafica_precio_compra_historial
                this.historial.series[1].data = data.grafica_precio_venta_historial
                this.titulo = `Historias de cambio de precios de ${this.nombre} en el cedis ${data.cedis.nombre}`

            } catch (e){
                this.error_catch(e)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
